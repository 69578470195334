<template>
  <div class="wrapper">
<!--    v-if="showSHT === true"-->
    <div>
      <vHead></vHead>
      <vSidebar :sideMenus="sideMenu"></vSidebar>
      <!--    <el-divider class="vd_color"></el-divider>-->
      <div class="content-box" :class="{'content-collapse':collapse}">
        <vTags @setSideMenu="setSideMenu"></vTags>
	      <!-- <el-scrollbar style="height: 100%"> -->
		      <div class="content">
			      <transition name="slide-fade" mode="out-in">
				      <keep-alive :include="tagsList">
					      <router-view :key="$route.fullPath"></router-view>
				      </keep-alive>
			      </transition>
			      <el-backtop target=".content" :bottom="100"></el-backtop>
		      </div>
	      <!-- </el-scrollbar> -->
      </div>
    </div>
    <transition name="fade">
        <div class="vd_refresh" v-show="flag">
          <el-card class="vd_card">
            <div class="vg_mb_8 vd_ca_f">版本已更新！</div>
            <div class="vg_mb_8">请先保存已填写的单据，防止丢失。</div>
            <div class="vd_ca_t"><el-button type="primary" size="small" @click="refreshClick">确认更新</el-button></div>
          </el-card>
        </div> 
      </transition>
<!--    <div v-if="showSHT === false">-->
<!--      <router-view></router-view>-->
<!--    </div>--> 
  </div>
</template>

<script>
import vSidebar from '@/components/SideMenu.vue';
import vHead from '@/components/TopHeader.vue';
import vTags from '@/components/Tags.vue';
import bus from '@/components/common/bus';
import {get,post} from "@api/request";
import {userAPI} from "@api/modules/user"
export default {
  data() {
    return {
      tagsList: [],
      collapse: false,
      showSHT: false,
      flag:false,
      myInter:null,
      myInterTime:null,
      myInterNum:0,
      updateType:1,
      sideMenu:'',
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags
  },
  watch: {
    // $route(to){
    //   if(to.fullPath === '/login') {
    //     this.showSHT = false;
    //     console.log('this.showSHT',this.showSHT)
    //   } else {
    //     this.showSHT = true;
    //   }
    // }
  },
  created() {
    bus.$on('collapse-content', msg => {
      this.collapse = msg;
    });

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on('tags', msg => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
  },
  mounted(){
    this.timer()
  },
  beforeDestroy(){
    clearInterval(this.myInter)
    this.myInter = null;
  },
  methods:{
    setSideMenu(val){
      this.sideMenu = val
    },
    timer(){
      this.myInter = setInterval(()=>{
        this.getVersion()
      },300000)
    },
    getVersion(){
      get(userAPI.getVersion)
      .then(res=>{
        if (res.data.code === 0) {
            if(sessionStorage.getItem('loaded') !== res.data.data.version+'') {
              clearInterval(this.myInter)
              this.myInter = null;
              this.flag = true;
              this.updateType = res.data.data.update_type
              if(res.data.data.update_type === 2){
                sessionStorage.setItem('loaded', res.data.data.version+'');
              }
            } 
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
      })
      .catch(() => { })
    },
    // 确认更新
    refreshClick(){
      this.flag = false;
      if(this.updateType === 1){
        get(userAPI.userLogout).then(res => {
          if (res.data.code === 0) {
            this.$cookies.remove('userInfo')
            this.$cookies.remove('push')
            this.jump('/login');
          }
        })
      }else if(this.updateType === 2){
        window.location.reload();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.vd_color{
  background-color: $color-primary;
  height: 2px;
} 
.el-backtop {
  margin-bottom: 25px;
}
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(-10px);
  opacity: 0;
}
.vd_refresh{
  width: 300px;
  position: fixed;
  right: 10px;
  top: 15%;
  z-index: 999;
}
.vd_card{
  background-color: rgb(229, 229, 229);
}
.vd_ca_f{
  font-size: 20px;
  color: rgb(255, 55, 0);
}
.vd_ca_t{
  display: flex;
  justify-content: flex-end;
}
.fade-enter-active,.fade-leave-active {
	transition: all .8s ease;
}
.fade-enter, .fade-leave-to{
  transform: translateX(100px);
  opacity: 0;
}
</style>
<style>
.el-scrollbar__thumb{
	background-color: #8d8d8d !important;
}
</style>