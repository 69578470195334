<template>
  <div class="vd_sidebar">
    <!--        background-color="$color-white"-->
    <!--        text-color="#000"-->
    <!--        active-text-color="$color-white"-->
    <el-menu
        class="vd_sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        unique-opened
        router
        @select="selectChange"
    >
      <template v-for="item in items">
        <template v-if="item.perm_child_list">
          <el-submenu :index="item.perm_url+'_list'+`?perm_id=${item.perm_id}`" :key="item.perm_url+'_list'">
            <template slot="title">
              <i :class="item.perm_icon"></i>
              <span slot="title">{{ item.perm_name }}</span>
            </template>
            <template v-for="subItem in item.perm_child_list">
              <template v-if="subItem.perm_id!==120">
                <el-submenu
                  v-if="subItem.perm_child_list"
                  :index="subItem.perm_url+'_list'+`?perm_id=${subItem.perm_id}`"
                  :key="subItem.perm_url+'_list'+`?perm_id=${subItem.perm_id}`+`?perm_id=${item.perm_id}`"
              >
                <template slot="title">{{ subItem.perm_name }}</template>
                <el-menu-item
                    v-for="(threeItem,i) in subItem.perm_child_list+'_list'+`?perm_id=${subItem.perm_id}`"
                    :key="i"
                    :index="threeItem.perm_url+`?perm_id=${threeItem.perm_id}`"
                >{{ threeItem.perm_name }}</el-menu-item>
              </el-submenu>
              <el-menu-item
                  v-else
                  :index="subItem.perm_url+'_list'+`?perm_id=${subItem.perm_id}`"
                  :key="subItem.perm_url+'_list'+`?perm_id=${subItem.perm_id}`"
              ><div class="vd_padd45" :class="[chooseIndex===subItem.perm_id?'vd_bgc':'']">
              {{ subItem.perm_name }}</div></el-menu-item>
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.perm_url" :key="item.perm_url" v-if="item.perm_id==8?false:true" >
            <i :class="item.perm_icon"></i>
            <span slot="title">{{ item.perm_name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '@/components/common/bus';
import {get} from "@/api/request";
import {userAPI} from "@/api/modules/user";

export default {
  name:"sideMenu",
  props:{
    sideMenus:{
      type:String
    }
  },
  data() {
    return {
      collapse: false,
      mode:null,
      procList:[],
      items: [
        {
          icon: 'el-icon-s-home',
          index: 'dashboard',
          title: '系统首页'
        }
      ],
      chooseIndex:0
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace('/', '');
    }
  }, 
  created() {
    this.getUserMode()
    this.getMenus()
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on('collapse', msg => {
      this.collapse = msg;
      bus.$emit('collapse-content', msg); 
    });
  },
  watch:{
    sideMenus(newValue,oldValue){
      // if(newValue !== oldValue){
      //   this.$refs.vSidebar.sideMenuChange()
      // }
      if(newValue){
        this.selectChange(newValue)
      }else{
        this.chooseIndex = -1
      }
    },
  },
  methods:{
    selectChange(e){
      let str = e.substr(e.indexOf('=')+1,e.length)
      this.chooseIndex = Number(str)
    },
    getMenus(){ 
      get(userAPI.logMenu)
      .then(res=>{
        if(res.data){
          this.items = res.data
          let userInfo = this.$cookies.get('userInfo')
          if(userInfo.acct_no+'@1' === userInfo.user_name){
            this.items = this.items.splice(0,2)
          }
          if(userInfo.acct_id !== 1){
            let temp = [];
            for(let i in this.items[1].perm_child_list){
              if(this.items[1].perm_child_list[i].perm_id === 107){
                temp.push(i)
              }
              if(this.items[1].perm_child_list[i].perm_id === 105){
                temp.push(i)
              }
              if(this.items[1].perm_child_list[i].perm_id === 104){
                temp.push(i)
              }
              if(this.items[1].perm_child_list[i].perm_id === 110){
                temp.push(i)
              }
            }
            temp = temp.sort((a,b)=>{return b-a})
            for(let i of temp){
              this.items[1].perm_child_list.splice(Number(i),1)
            }
          }
          // this.items[5].perm_child_list.splice(0,1)
        } else {
          this.items = null
        }
      })
      .catch(()=>{
        this.$router.push('/login')
        this.deleteCookie()
      })
    },
    // 删除cookie
    deleteCookie(){
      get(userAPI.userLogout).then(res => {
        if (res.data.code === 0) {
          this.$cookies.remove('userInfo')
          this.$cookies.remove('push')
        }
      }).catch(()=>{})
    },
    getUserMode(){
      this.mode = this.$cookies.get('userMode').type
      if(this.mode===1 ){ 
        this.collapse = true
      }
    },
  }
};
</script>

<style scoped>
.vd_sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.vd_sidebar::-webkit-scrollbar {
  width: 0;
}
.vd_sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.vd_sidebar > ul {
  height: 100%;
}
.el-menu i {
  /* color: #fff; */
  /* #4a5259 */
}
::v-deep .vd_bgc{
  background-color: #c0c2e35c;
}
::v-deep .vd_sidebar-el-menu .is-active{
  background-color: #c0c2e35c !important;
}
::v-deep .vd_sidebar-el-menu .el-submenu{
  /* background-color: #5ab5ff59 !important; */
}
::v-deep .el-submenu .el-menu-item {
  padding: 0 !important;
}
.vd_padd45{
  padding: 0 45px;
}
</style>
